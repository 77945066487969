import Container from "@mui/material/Container";
import Typography from "@mui/material/Typography";
import Box from "@mui/material/Box";
import {Image} from "mui-image";
import LogoTransp from "../assets/logo_transp.png";

function Home() {
  return (
    <Container
      spacing={22}
      direction="column"
    >
      <Box
        display="flex"
        justifyContent="center"
        alignItems="center"
        style={{marginTop: "50px", marginBottom: "80px"}}
      >
        <Typography
          variant="h5"
          component="div"
        >
          July 11-12 2025
        </Typography>
      </Box>
      <Box>
        <Image
          style={{borderRadius: "1%"}}
          src={LogoTransp}
        />
      </Box>
    </Container>
  );
}

export default Home;
