import Typography from "@mui/material/Typography";

const Lineup = () => {
  return (
    <>
      <Typography
        variant="h5"
        component="div"
        style={{marginTop: "15px", marginBottom: "10px"}}
      >
        BANDS
      </Typography>

      <Typography variant="body2">Ancient Emblem (DE)</Typography>
      <Typography variant="body2">Counterblast (SE)</Typography>
      <Typography variant="body2">Kovaa Rasvaa (FI)</Typography>
      <Typography variant="body2">Nödvärn (SE)</Typography>
      <Typography variant="body2">Nagasaki Sunrise (PT)</Typography>
      <Typography variant="body2">Skrot (SE)</Typography>
      <Typography variant="body2">Stormen Gorm (SE)</Typography>

      {/*<Typography variant="body2"><a*/}
      {/*  href={*/}
      {/*    "https://agrimoniasl.bandcamp.com/"*/}
      {/*  }*/}
      {/*  target="_blank"*/}
      {/*  rel="noreferrer"*/}
      {/*>*/}
      {/*  Agrimonia*/}
      {/*</a> (SE)</Typography>*/}
      {/*<Typography variant="body2"><a*/}
      {/*  href={*/}
      {/*    "https://asocialattack.bandcamp.com/"*/}
      {/*  }*/}
      {/*  target="_blank"*/}
      {/*  rel="noreferrer"*/}
      {/*>*/}
      {/*  Asocial*/}
      {/*</a> (SE)</Typography>*/}

      {/*<Typography*/}
      {/*  variant="h6"*/}
      {/*  component="div"*/}
      {/*  style={{ marginTop: "15px", marginBottom: "10px" }}*/}
      {/*>*/}
      {/*  Check out this <a*/}
      {/*    href={*/}
      {/*      "https://www.youtube.com/playlist?list=PL6dpd1j-ROECd5WELeCHioSiDHLqQwNGg"*/}
      {/*    }*/}
      {/*    target="_blank"*/}
      {/*    rel="noreferrer"*/}
      {/*  >YouTube playlist</a> to hear some more music from the bands!*/}
      {/*</Typography>*/}
      {/*<Typography*/}
      {/*  variant="h5"*/}
      {/*  component="div"*/}
      {/*  style={{ marginTop: "25px", marginBottom: "10px" }}*/}
      {/*>*/}
      {/*  DJs*/}
      {/*</Typography>*/}
      {/*<Typography variant="body2">The Ratpack Crew - Fiffifreedom & Kloakerkan</Typography>*/}
      {/*<Typography variant="body2">Moahawk & Siv Saliv</Typography>*/}
      {/*<Typography*/}
      {/*  variant="h5"*/}
      {/*  component="div"*/}
      {/*  style={{ marginTop: "25px", marginBottom: "10px" }}*/}
      {/*>*/}
      {/*  RUNNING ORDER*/}
      {/*</Typography>*/}
      {/*<Typography variant="h6">Friday*/}
      {/*</Typography>*/}
      {/*<Typography variant="body2">18.00: Warför*/}
      {/*</Typography>*/}
      {/*<Typography variant="body2">19.00: Böset*/}
      {/*</Typography>*/}
      {/*<Typography variant="body2">20.00: Barräkas*/}
      {/*</Typography>*/}
      {/*<Typography variant="body2">21.00: Varoitus*/}
      {/*</Typography>*/}
      {/*<Typography variant="body2">23.00: he Lost his iro in an apocalipstix world*/}
      {/*</Typography>*/}
      {/*<Typography variant="body2">24:00: Sju svåra år*/}
      {/*</Typography>*/}
      {/*<Typography variant="body2">01:00: Tolshock*/}

      {/*</Typography>*/}
      {/*<Typography variant="h6">Saturday*/}
      {/*</Typography>*/}
      {/*<Typography variant="body2">16.00: Terror 83*/}
      {/*</Typography>*/}
      {/*<Typography variant="body2">17.00: Heavy bleeding*/}
      {/*</Typography>*/}
      {/*<Typography variant="body2">18:00: Vivisectio*/}
      {/*</Typography>*/}
      {/*<Typography variant="body2">19:00: Agrimonia*/}
      {/*</Typography>*/}
      {/*<Typography variant="body2">20.00: Kovaa Rasvaa*/}
      {/*</Typography>*/}
      {/*<Typography variant="body2">21.00: Asocial*/}
      {/*</Typography>*/}
      {/*<Typography variant="body2">22.00: Indre krig*/}
      {/*</Typography>*/}
      {/*<Typography variant="body2">23.00: Avskum*/}
      {/*</Typography>*/}
      {/*<Typography variant="body2">24.00: Protestera*/}
      {/*</Typography>*/}
      {/*<Typography variant="body2">01.00: Dropdead*/}
      {/*</Typography>*/}
    </>
  );
};

export default Lineup;
