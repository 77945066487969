import Typography from "@mui/material/Typography";
import {List, ListItem} from "@mui/material";
import Box from "@mui/material/Box";

const InfoEn = () => {
  return (
    <>
      <Typography
        variant="h5"
        component="div"
        style={{marginTop: "15px", marginBottom: "10px"}}
      >
        INFO
      </Typography>
      <Typography variant="body2">
        Welcome to Svartmyra, outside of Värnamo, Sweden during July 11-12 2025! The camp site will be open from Friday
        at noon until Sunday at 3 p.m. From then on, staff from Punk Illegal will be around answering your questions.
        The festival starts on Friday 11/7. The ticket and information center, where tickets are exchanged for wrist
        bands, is open Friday and Saturday from noon to midnight. No tolerance for violence, racism, sexism, or
        homophobia! And people, take care of each other and have fun! Remember, punk is love!
      </Typography>
      <Typography
        variant="h5"
        component="div"
        style={{marginTop: "15px", marginBottom: "10px"}}
      >
        TICKETS
      </Typography>
      <Typography variant="body2">
        Tickets are only sold through:{" "}
        <Box
          component="span"
          fontWeight="bold"
        >
          <a
            href={"https://www.biljettkiosken.se/punkillegal"}
            target="_blank"
          >
            biljettkiosken.se/punkillegal
          </a>{" "}
        </Box>
      </Typography>
      <Typography variant="body2">
        The ticket costs SEK 450 and is valid both days.There will be no one day tickets unless we have spare tickets.
        It is important to note that Biljettkiosken charges a service fee in addition to this price. The price also
        includes camping. Note! No tickets will be sold on site!{" "}
        <Box
          component="span"
          fontWeight="bold"
        >
          Note! No tickets will be sold on site!
        </Box>
      </Typography>
      <Typography
        variant="h5"
        component="div"
        style={{marginTop: "15px", marginBottom: "10px"}}
      >
        FOOD
      </Typography>
      <Typography variant="body2">
        There will be vegan food for sale both through Punk Illegal and D-beat Warmachine food truck. You can choose
        from different vegan options. Ask about allergies. There will also be beverages for sale.
      </Typography>
      <Typography
        variant="h5"
        component="div"
        style={{marginTop: "15px", marginBottom: "10px"}}
      >
        CAMP SITE
      </Typography>{" "}
      <Typography variant="body2">
        There is a camp site free of charge, both for tents, cars and trucks. Park in the designated areas and keep fire
        corridors free! Simple toilets & running water will be on site. Nearby there are also bathing spots. At the
        campsite, it is important that everyone respects each other. Do not light fires anywhere on or close to the
        festival area, since there is a risk of fire in the woods and the place is surrounded by wooden houses. Camping
        kitchens are allowed but be careful when using them! Use the toilets and keep them as clean as possible. Please
        use the right recycling bins for glass, plastic, cans, cardboard, and paper! Please throw cigarette stumps and
        snus in the provided glass jars. Please, clean up around your tent site before you go home, and respect your
        camping neighbors!! It is forbidden to stay at the camp site without a ticket!
      </Typography>
      <Typography
        variant="h5"
        component="div"
        style={{marginTop: "15px", marginBottom: "10px"}}
      >
        OTHER PLACES TO SLEEP
      </Typography>
      <Typography variant="body2">
        We recommend that you camp in the festival area since there are no hotels close by, and public transports there
        are almost non existing or stop far away. If you are still looking we can recommend cabins at Värnamo camping,
        but bear in mind that you need a car to get there:
        <Box
          component="span"
          fontWeight="bold"
        >
          <a
            href={"https://camping.se/en/camping/2677/Varnamo-Camping-Prostsjon"}
            target="_blank"
            rel="noreferrer"
          >
            camping.se/en/camping/2677/Varnamo-Camping-Prostsjon
          </a>{" "}
        </Box>
      </Typography>
      <Typography variant="body2">PAYMENT AT THE FESTIVAL</Typography>
      <Typography variant="body2">
        Bring Swedish cash or use Swish (Swedish mobile pay). No credit cards can be used. There is no ATM close by, so
        make sure to get money out beforehand!
      </Typography>
      <Typography
        variant="h5"
        component="div"
        style={{marginTop: "15px", marginBottom: "10px"}}
      >
        MERCH/RECORDS
      </Typography>
      <Typography variant="body2">
        Most of the bands bring their own merch and sell it in the merch area. There will also be other distros at the
        festival area.
      </Typography>
      <Typography
        variant="h5"
        component="div"
        style={{marginTop: "15px", marginBottom: "10px"}}
      >
        DOGS AND KIDS
      </Typography>
      <Typography variant="body2">
        Dogs can come, but keep them on leashes since there are other animals on the festival site. We prefer not to see
        dogs in front of the stage due to loud music. Children up to 13 are only allowed in with a guardian (child goes
        in free of charge), but make sure they have ear plugs! Young people under 18 will receive a special wrist band
        but pay full price.
      </Typography>
      <Typography
        variant="h5"
        component="div"
        style={{marginTop: "15px", marginBottom: "10px"}}
      >
        SPRAY CANS AND TAG PENS
      </Typography>
      <Typography variant="body2">Please leave spray cans and tag pens at home!</Typography>
      <Typography
        variant="h5"
        component="div"
        style={{marginTop: "15px", marginBottom: "10px"}}
      >
        CAR
      </Typography>
      <Typography variant="body2">
        The easiest way to get to Svartmyra is by car so try and find someone with a car to go with.
      </Typography>
      <Typography
        variant="h5"
        component="div"
        style={{marginTop: "15px", marginBottom: "10px"}}
      >
        FLIGHTS
      </Typography>
      <Typography variant="body2">
        The nearest airports are situated in Växjö and Jönköping, but they fly to few destinations. Otherwise,
        Copenhagen and Gothenburg airports are approximately 3 hours away by bus/train.
      </Typography>
      <Typography
        variant="h5"
        component="div"
        style={{marginTop: "15px", marginBottom: "10px"}}
      >
        TRAINS
      </Typography>
      <Typography variant="body2">The nearest train station is situated in Värnamo.</Typography>
      {/*<Typography*/}
      {/*  variant="h5"*/}
      {/*  component="div"*/}
      {/*  style={{marginTop: "15px", marginBottom: "10px"}}*/}
      {/*>*/}
      {/*  SHUTTLE BUS*/}
      {/*</Typography>*/}
      {/*<Typography variant="body2">*/}
      {/*  If you travel by train and need picking up from Värnamo train station we need to know so that we can pick you*/}
      {/*  up. The festival is situated about 30 kilometers outside Värnamo. Here is some useful information about the*/}
      {/*  shuttle service:*/}
      {/*  <List*/}
      {/*    sx={{listStyleType: "disc", pl: 4}}*/}
      {/*    dense*/}
      {/*  >*/}
      {/*    <ListItem sx={{display: "list-item"}}>*/}
      {/*      {" "}*/}
      {/*      The shuttle runs only Friday 12th and Sunday 14th July. On Friday, we will pick people upp between noon and*/}
      {/*      8 pm. On Sunday we will drive people between 10 am and 2 pm.*/}
      {/*    </ListItem>*/}
      {/*    <ListItem sx={{display: "list-item"}}>*/}
      {/*      The shuttle service costs 50 Swedish kronor (Swish or cash) or 5 Euros (cash) one way.*/}
      {/*    </ListItem>*/}
      {/*    <ListItem sx={{display: "list-item"}}>*/}
      {/*      The shuttle is a 9 seater mini van, and it will stop outside the train station in Värnamo.*/}
      {/*    </ListItem>*/}
      {/*    <ListItem sx={{display: "list-item"}}>*/}
      {/*      The bus will leave when it is full and this means that you might need to wait for a bit in Värnamo or that*/}
      {/*      you may need to go back an hour earlier or so to Värnamo than your train leaves.*/}
      {/*    </ListItem>*/}
      {/*    <ListItem sx={{display: "list-item"}}>*/}
      {/*      The bus takes 8 passengers which means you might need to wait if the bus is full until the bus comes back*/}
      {/*      again.*/}
      {/*    </ListItem>*/}
      {/*  </List>*/}
      {/*  <Typography variant="h6">*/}
      {/*    <Box*/}
      {/*      component="span"*/}
      {/*      fontWeight="bold"*/}
      {/*    >*/}
      {/*      <a*/}
      {/*        href={"https://docs.google.com/forms/d/1ln2vlivgNJq_gLxNTEswJQ8wPX6SYQUmwGovS7yK8oQ/edit?chromeless=1"}*/}
      {/*        target="_blank"*/}
      {/*        rel="noreferrer"*/}
      {/*      >*/}
      {/*        SIGN UP FOR THE SHUTTLE BUS HERE*/}
      {/*      </a>{" "}*/}
      {/*    </Box>*/}
      {/*  </Typography>*/}
      {/*</Typography>*/}
      <Typography
        variant="h5"
        component="div"
        style={{marginTop: "15px", marginBottom: "10px"}}
      >
        AVAILABILITY
      </Typography>
      <Typography variant="body2">
        <List
          sx={{listStyleType: "disc", pl: 4}}
          dense
        >
          <ListItem sx={{display: "list-item"}}>
            {" "}
            <Box
              component="span"
              fontWeight="bold"
            >
              Handicap toilet:
            </Box>{" "}
            Yes, in form of portable toilets.
          </ListItem>

          <ListItem sx={{display: "list-item"}}>
            <Box
              component="span"
              fontWeight="bold"
            >
              The area:
            </Box>{" "}
            Most of the area consists of uneven ground and it can be difficult to access. Good to have someone with you
            who can help push if you are in a wheelchair.
          </ListItem>
          <ListItem sx={{display: "list-item"}}>
            <Box
              component="span"
              fontWeight="bold"
            >
              Companions:
            </Box>{" "}
            Companions and carers enter free of charge upon presentation of. Accompanying dogs as well but not during a
            concert.
          </ListItem>
        </List>
      </Typography>
    </>
  );
};

export default InfoEn;
